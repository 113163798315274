@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css?family=Heebo:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

* {
  box-sizing: border-box;
}


$component-editor-width:21em;
$zmash-purple:#48399E;
$zmash-purple-hover:#6656c2;
$zmash-purple-active:#776bc7;
$collect-yellow: #e3bd01;
$save-application-green: #26935c;
$main-menu-height:4em;
$node-width:180px;
.flowEditor {
  height: 100%;
  overflow: auto;
  position: relative;
  .save-screen-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000070;
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;

    .save-screen-modal {
      width: 10em;
      height: 5em;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: .5em;
      box-shadow: 2px 2px 6px 3px #00000080;
      font-size: 1.5em;
    }
  }
  header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;

    .flow-title,
    .flow-type {
      color: #fff;
      font-size: .75em;
      height: auto;
      padding: .5em 1em .5em .5em;
    }

    .flow-type {
      color: #000;
      background: $collect-yellow;
    }

    .flow-title {
      background: $zmash-purple;
      border-radius: 0 0 .75em 0;
    }
  }
  .canvas {
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    position: absolute;
    transition: left 300ms ease;
    &.move {
      left: $component-editor-width;
    }
    .arrow-container {
      height: 100%;
      >svg {
        pointer-events: none;
        z-index: 99;
      }
    }

    .step-wrapper {
      position: absolute;
      top: 0;
      left: 0; // padding: 0 2em;
      display: flex;
      overflow: auto;
      min-height: 100%;
      box-shadow: 0.2em 0.2em 0.5em #3333338c;
    }

    steps {
      height: 100%;
    }

    .header {
      display: block;
      min-width: $node-width;
      h2,
      input {
        display: block;
        margin: 1em auto;
        font-size: 1.25em;
        text-align: center;
        border: 1px solid #333;
        line-height: 1em;
        height: 1em;
        padding: .5em;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        -webkit-appearance: none;
        box-sizing: content-box;
      }
      h2 {
        border: 1px solid transparent;
      }
    }
    .flow-step {
      background-color: #eaeaea;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      .xarrow {
        z-index: 9999;
        >path {
          pointer-events: none
        }
      }
      h1 {
        color: #aaa;
      }
      &.pseudo .nodes {
        align-items: center;
        display: flex;
        justify-content: center;
      }
      .nodes {
        padding: 0 2em 2em 2em;
        flex-grow: 1;
        display: flex;
        .new-lane {
          width: 0;
          overflow: hidden;
          transition: ease 150ms all;
          .dropzone {
            margin-left: 1rem;
            width: 4rem;
            border: 3px dashed lightgrey;
            border-radius: .5rem;
            background: #ddd;
            box-sizing: border-box;
            writing-mode: vertical-lr;
            padding-top: 30rem;
            display: flex;
            align-items: center;
            font-size: 2em;
            color: #888;
            text-overflow: nowrap;
          }
          .dropzone:hover {
            background: $zmash-purple-active;
            color: #fff;
            border-color: #fff;
            border-style: solid;
          }
        }
        .new-lane.is-dragging {
          width: 5em;
        }
        .lane {
          display: flex;
          min-width: $node-width;
          flex-direction: column;
          &+.lane {
            padding-left: 1em;
            margin-left: 1em;
            border-left: 5px dotted lightgray;
          }
        }
        .flow-node-wrapper {
          .flow-node-inner-wrapper {
            transition: 150ms ease all;
            border-radius: .4em;
            &.is-dragging {
              box-shadow: 0 0 2px 2px #48399e;
            }
          }
          &+.flow-node-wrapper {
            margin-top: 1em;
          }
          .flow-node {
            border: 1px solid $zmash-purple;
            border-radius: .4em;
            box-shadow: 1px 1px 1px #0000004a;
            position: relative;
            background: #fff;
            transition: all 300ms ease;
            font-family: "Heebo";
            font-weight: 500;
            font-size: 12.25px;

            text-align: center;
            width: $node-width;
            &:hover {
              border-color: $zmash-purple-hover;
              box-shadow: 2px 2px 5px #0000004a;
              h3 {
                background: $zmash-purple-hover;
              }
            }
            .isId {
              background: $collect-yellow;
            }
            .actions {
              padding: .5em 1em;

              .action {
                margin: 1em 0;

                &.question {
                  border-left: 2px solid #48399E;
                  border-radius: 5px;
                  padding: 0 0 0 1em;
                  .quick-replies {
                    margin-top: .5em;
                    >span {
                      display: inline-block;
                      border-left: 2px solid #000;
                      border-right: 2px solid #000;
                      border-radius: 5px;
                      padding: .0 .5em 0 .5em;

                    }
                    .replies {
                      max-height: 0;
                      overflow: hidden;
                      transition: 500ms ease all;
                    }
                    &.opened {
                      .replies {
                        max-height: 200px;
                        overflow: hidden;
                      }
                    }
                  }
                  .variable {
                    background: $collect-yellow;
                    margin: 0 .5em .5em .5em;
                    width: auto;
                    border-radius: 1em;

                  }

                }
                &.saveApplication,
                &.saveLead,
                &.saveFeedback {
                  border-left: 2px solid $save-application-green;
                  border-radius: 5px;
                  padding: 0 0 0 1em;
                  .message {
                    color: #fff;
                    padding: .5em;
                    background: $save-application-green;
                  }
                  .variable {
                    border: 2px solid $save-application-green;
                    margin: 0 .5em .5em .5em;
                    width: auto;
                    border-radius: 1em;
                    margin-top: .5em;
                  }

                }
                &.question.collect {
                  border-color: $collect-yellow;
                }
                &.question .answer {
                  display: flex;
                  flex-direction: row-reverse;
                }
                &.question .answer-button,
                &.goToNode,
                &.personalData .answer {
                  border: 1px solid $zmash-purple;
                  border-radius: .5em;
                  display: block;
                  min-height: 2em;
                  line-height: 2em;
                  margin-top: .5em;
                  padding: 0 1em;
                  position: relative;
                  text-align: center;
                  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
                  .out-anchor-wrapper {
                    margin-top: -.5em;
                    position: absolute;
                    height: 1em;
                    right: -1.7em;
                    top: 50%;
                    width: 1em;
                    z-index:1;
                    .out-anchor {
                      width: 1em;
                      height: 1em;
                      background-color: #48399e;
                      border-radius: 50%;
                    }
                  }
                }
                &.message,
                .message {
                  // background: #ddd;
                  background: #eaeaea;
                  padding: .5em 1em;
                  border-radius: .5em;
                  text-align: left;
                  padding: .5em .75em;
                  box-shadow: 0px 1px 2px 1px #0000003f;

                  .link-cta {
                    opacity: .5;
                    margin-top: .5em;
                    background: #fff;
                    border: none;
                    border-radius: .5rem;
                    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
                    font-weight: 500;
                    padding: .25rem 1rem;
                    text-align: center;
                    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
                  }
                }
                .variable-highlight {
                  background: $collect-yellow;
                }
              }
            }
            h3 {
              border-radius: .4em .4em 0 0;
              padding: .5em .5em .5em .8em;
              background: $zmash-purple;
              color: #fff;
              transition: background 300ms ease;
              letter-spacing: 1px;
              text-transform: uppercase;
              font-size: .8em;
              margin: 0;
            }
            .menu {
              padding: .5em 1em;
              .btn {
                color: $zmash-purple;
                width: 100%;
                text-decoration: none;
              }
            }
            &>.message {
              padding: 1.5em 0 0 0;
            }

          }
        }
        * {
          cursor: pointer;
        }

      }
      #add-step {
        display: block;
        color: #aaa;
        font-size: 1.5em;
        transition: color ease 250ms, transform ease 50ms;
        user-select: none;
        i {
          font-size: 5em;
          display: block;
        }
        &:hover {
          color: #666;
        }
        &:active {
          transform: scale(.93)
        }
      }
    }
    .menu {
      display: block;
      align-self: flex-end;
      width: 100%;
      padding: 1em;
      .btn {
        opacity: .5;
      }
    }
    >menu {
      position: absolute;
    }
    button,
    .btn {
      background: #ffffff;
      border: 2px solid $zmash-purple;
      border-radius: 1.5em;
      cursor: pointer;
      display: block;
      line-height: 1em;
      margin: .5em auto;
      padding: .5em;
      text-align: center;
      width: 14em;
      transition: all ease 150ms;
      box-shadow: 0 0 0 0;
      &:hover {
        opacity: 1;
        box-shadow: 2px 2px 5px #0000004a;
      }
      &:active {
        transform: scale(.95);
        color: #555;
        border-color: #776bc7;
      }
    }
    .btn.small {
      font-size: .7em;
      padding: .5 0;
    }
  }

  .component-editor {
    background-color: #ffffffaa;
    box-shadow: inset -0.3em 0 10px #00000087;
    height: 100%;
    left: -$component-editor-width;
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: left 300ms ease;
    width: $component-editor-width;
    z-index: 99;
    padding-bottom: 4em;
    .node-editor {
      height: 100%;
      overflow-y: auto;
      .editor-wrapper {
        display: flex;
        height: 100%;
        flex-direction: column;

        .header {
          display: flex;
          justify-content: space-between;

          h2,
          input {
            width: 12em;
            display: block;
            margin: .5em .5em;
            font-size: 1.25em;
            text-align: left;
            border: 1px solid #333;
            line-height: 1em;
            height: 1em;
            padding: .5em;
            font-weight: 700;
            font-family: 'Poppins', sans-serif;
            -webkit-appearance: none;
            box-sizing: content-box;
          }
          h2 {
            border: 1px solid transparent;
          }
          a.save {
            cursor: pointer;
            display: flex;
            &>i {
              line-height: 2.5em;
              &:hover {
                color: #999
              }
              &:active {
                color: #bbb;
              }
            }
          }
        }
        .properties {
          margin: 0 1em;
          >div {
            border-left: 2px solid #aaa;
            padding: 0 1em;
          }
        }
        h3 {
          margin: 0 0;
          text-align: center;
        }
        .actions {
          border: 4px dashed #ccc;
          border-radius: 10px;
          flex-grow: 1;
          font-size: .8em;
          margin: 1em;
          .no-actions {
            padding: 2em;
            text-align: center;
          }
          .action {
            display: flex;
            justify-content: space-between;
            animation: slide-up 0.4s ease;
            &.goToNode {
              margin-top: 1em;

              &>div {
                border: 2px solid $zmash-purple;
                border-radius: 1.5em; // height: 2em;
                line-height: 2em;
                margin: .5em 1em;
                padding: 0 5em;
                text-align: center;
              }
              &>i {
                margin: .3em .7em;
              }
            }
            &.collectQuestionFixed,
            &.collectQuestionFree {
              border-color: $collect-yellow !important;
              padding-top: 1em;
            }
            &.saveApplication,
            &.saveFeedback,
            &.saveLead {
              border-color: $save-application-green !important;
              padding-top: 1em;
            }
            &.messageWithLink {
              border-color: #ddd !important;
              display: block;
              .message-extra {
                text-align: center;
                margin-top: 1em;
                .button-cta {
                  border: none;
                  border-radius: .5rem;
                  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
                  font-weight: 500;
                  padding: .5rem 1rem;
                  text-align: center;
                  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
                }
              }
            }
            &.question,
            &.personalData,
            &.collectQuestionFixed,
            &.collectQuestionFree,
            &.saveApplication,
            &.saveFeedback,
            &.saveLead,
            &.messageWithLink {
              margin: .5em;
              border-left: 2px solid $zmash-purple;
              border-radius: 10px;
              position: relative;
              select,
              .label {
                padding: .5em 1em;
                border: 2px solid $collect-yellow;
                border-radius: 2em;
                width: 15em;
              }
              select:focus {
                outline: none;
              }
              .label {
                margin-bottom: 1em;
                margin-left: auto;
                margin-right: auto;
                border-color: $save-application-green;
              }
              &>i {
                position: absolute;
                top: 0;
                right: 0;
                margin-right: .42em;
              }
              .question-contents,
              .save-application-contents {
                display: flex;
                flex-direction: column;
                text-align: center;
                width: 100%;
                .message-content {
                  max-width: 15em;
                  flex-shrink: 1;
                }
                .quick-replies {
                  >span {
                    margin: .5em auto;
                    padding: 0 .5em;
                    border-left: 2px #000 solid;
                    border-right: 2px #000 solid;
                    border-radius: .5em;
                    display: inline-block;
                    text-align: center;
                  }
                }
                .answers,
                .variables {
                  display: flex;
                  flex-flow: row-reverse wrap;
                  margin-bottom: 1em;
                  width: 100%;
                  .answer-row,
                  .variable-row {
                    display: flex;
                    align-items: center;
                    i {
                      font-size: 1rem;
                      padding-right: .5em;
                      opacity: 0;
                    }
                    &:hover i {
                      opacity: 1
                    }
                    &+.answer-row,
                    &+.variable-row {
                      margin-top: .5em;
                    }
                  }


                }

                i {
                  cursor: pointer;
                  color: #666;
                  &:hover {
                    color: #ccc
                  }
                  &:active {
                    color: #aaa;
                  }
                }
              }
              .answer,
              .variable,
              .link-href {
                border: 2px solid $zmash-purple;
                border-radius: 1.5em;
                min-height: 2em;
                line-height: 2em;
                padding: 0 1em;
                text-align: center;
                &:focus {
                  outline: none;
                  box-shadow: 0 0 0.3em #6656c2;
                }
              }

              .linkhref {
                align-self: center;
              }
            }
            &.personalData .message-content {
              font-family: 'Poppins', sans-serif;
              font-size: 1em;
              display: block;
              overflow: hidden;
              white-space: normal;
              overflow-wrap: normal;
              text-align: left;
              resize: none;
              min-height: 25px;
              padding: 10px;
              width: 100%;
              line-height: 1.2em;
              background: #ddd;
              border: none;
              border-radius: .5em;
            }

            .message-content {
              position: relative;
              margin: .5em 1em;
              max-width: 15em;

              textarea {
                height: 100%;
                position: absolute;
                &:focus {
                  outline: none;
                  box-shadow: 0 0 0.3em #6656c2;
                }
              }
              .hidden-textarea {
                white-space: pre-wrap;
                visibility: hidden;
              }
              .hidden-textarea,
              textarea {
                font-family: 'Poppins', sans-serif;
                font-size: 1em;
                display: block;
                overflow: hidden;
                white-space: normal;
                overflow-wrap: normal;
                text-align: left;
                margin: 0 auto;
                resize: none;
                min-height: 25px;
                padding: 10px;
                width: 100%;
                line-height: 1.2em;
                background: #ddd;
                border: none;
                border-radius: .5em;
              }
            }
            &>i {
              cursor: pointer;
              align-self: flex-end;
              margin: .5em .7em;
              color: #ddd;
              &:hover {
                color: #ccc
              }
              &:active {
                color: #aaa;
              }
            }
          }
        }
      }

      .menu {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        nav {
          ul {
            margin: 0;
            list-style: none;
            padding: .5em
          }
          &.action-menu {
            ul {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              padding: .5em;
              border: 2px solid $zmash-purple;
              border-radius: .5em .5em 0 0;
              background: #fff;
              box-shadow: 10px 0 25px;
              li {
                display: block;
                margin: .3em;
                a {
                  border-radius: .3em;
                  background: $zmash-purple;
                  color: #fff;
                  cursor: pointer;
                  display: block;
                  font-size: .7em;
                  opacity: 1;
                  padding: .8em .5em;
                  transition: all ease 100ms;
                  text-align: center;
                  i {
                    display: block;
                  }
                  span {}
                  &:hover {
                    opacity: .9;
                    box-shadow: 2px 2px 5px #0000004a;
                  }
                  &:active {
                    transform: scale(.98);
                    background-color: $zmash-purple-active;
                  }
                }
                &+li {
                  // border-left:5px dashed #aaa;
                }
              }
            }
          }
          &.main-menu {
            display: flex;
            background: #ddd;
            ul {
              display: flex;
              padding: 0;
              flex-grow: 1;
              li {
                color: $zmash-purple;
                cursor: pointer;
                height: $main-menu-height; // box-shadow: inset 0 0 0.5em #0009;
                flex-grow: 1;
                text-align: center;
                transition: opacity ease 100ms;
                width: 50%;
                &+li {
                  // border-left: 1px solid #666;
                }
                a {
                  display: block;
                  height: 100%;
                  width: 100%;
                  padding: .5em;
                  span {
                    display: block;
                    font-size: .8em;
                  }
                  i {
                    display: block;
                    font-size: 2em;
                  }
                }
                &:hover {
                  opacity: .9;
                  background: #eee;
                }
                &:active {
                  opacity: .85;
                }
                &.red {
                  background: #b02020;
                  i {
                    color: #fff;
                  }
                }
                &.save {
                  color: #fff;
                  background: $zmash-purple;
                  i {
                    color: #fff;
                  }
                }
              }
              &+ul {
                // border-left: 1px solid #666;
              }
              &.square {
                flex-grow: 0;
                li {
                  width: 4em;
                  a {
                    padding: 0;
                    width: 100%;
                    overflow: hidden;
                    color: #aaa;
                    i {
                      line-height: 2em;
                    }
                  }
                }
              }
            }

          }
        }
      }
    }
    .subtle {
      color: #999;
    }
  }

  .component-editor.show {
    left: 0;
  }
  .floating-menu {
    position: fixed;
    bottom: 0;
    right: 0;
    #client-flows {
      width: 15em;
      li {
        display: flex;
        span {
          flex-grow: 1;
        }
        button {
          background: none;
          border: none;
        }
      }
    }
    #settings,
    #client-flows {
      background: #fff;
      border: 2px solid $zmash-purple;
      border-radius: 1em;
      padding: .5em;
      margin: 1em;
      animation: slide-up 0.4s ease;
      justify-content: center;
      h2,
      h3 {
        text-align: center;
        margin: .2em 0;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          border: 2px solid $collect-yellow;
          border-radius: 2em;
          display: flex;
          font-size: .75em;
          line-height: 2em;
          padding: .2em .5em;
          text-align: center;
          input {
            border: none;
          }
          input:disabled {
            background: #fff;
          }
          div {
            flex-grow: 1;
          }
          &+li {
            margin-top: .5em;
          }
          i {
            cursor: pointer;
          }
        }

      }

    }
    #floating-btns {
      position: relative;
    }
    .floating-btn {
      width: 5em;
      height: 5em;
      border-radius: 50%;
      color: #fff;
      margin: 1em;
      margin-left: auto;
      box-shadow: 0 0 0 #00000000;
      transition: ease all 200ms;
      cursor: pointer;
      opacity: 1;
      background: #ccc;
      position: relative;
      i {
        font-size: 3em;
        text-align: center;
        display: block;
        line-height: 5rem;
      }
      span {
        opacity: 0;
      }
      &.save-btn {
        background: green;
        i {
          line-height: 4.5rem;
        }
      }
      &:hover {
        box-shadow: 0.1em 0.1em 0.4em #00000082;
        span {
          opacity: 1;
          font-size: .8em;
          display: block;
          position: absolute;
          top: -2em;
          background: #777;
          padding: .5em;
          width: 6em;
          border-radius: 1em;
          box-shadow: 0.1em 0.1em 0.4em #00000082;
          animation: slide-up 0.4s ease;
          text-align: center;
        }
      }
      &:active {
        opacity: .9;
      }
    }
  }
}

.flow-step+.flow-step {
  border-left: 3px dashed lightgray;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
