@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css?family=Heebo:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
#root, html, body{  height:100%;}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box; }

.flowEditor {
  height: 100%;
  overflow: auto;
  position: relative; }
  .flowEditor .save-screen-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000070;
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center; }
    .flowEditor .save-screen-wrapper .save-screen-modal {
      width: 10em;
      height: 5em;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: .5em;
      box-shadow: 2px 2px 6px 3px #00000080;
      font-size: 1.5em; }
  .flowEditor header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex; }
    .flowEditor header .flow-title,
    .flowEditor header .flow-type {
      color: #fff;
      font-size: .75em;
      height: auto;
      padding: .5em 1em .5em .5em; }
    .flowEditor header .flow-type {
      color: #000;
      background: #e3bd01; }
    .flowEditor header .flow-title {
      background: #48399E;
      border-radius: 0 0 .75em 0; }
  .flowEditor .canvas {
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    position: absolute;
    transition: left 300ms ease; }
    .flowEditor .canvas.move {
      left: 21em; }
    .flowEditor .canvas .arrow-container {
      height: 100%; }
      .flowEditor .canvas .arrow-container > svg {
        pointer-events: none;
        z-index: 99; }
    .flowEditor .canvas .step-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      overflow: auto;
      min-height: 100%;
      box-shadow: 0.2em 0.2em 0.5em #3333338c; }
    .flowEditor .canvas steps {
      height: 100%; }
    .flowEditor .canvas .header {
      display: block;
      min-width: 180px; }
      .flowEditor .canvas .header h2,
      .flowEditor .canvas .header input {
        display: block;
        margin: 1em auto;
        font-size: 1.25em;
        text-align: center;
        border: 1px solid #333;
        line-height: 1em;
        height: 1em;
        padding: .5em;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        -webkit-appearance: none;
        box-sizing: content-box; }
      .flowEditor .canvas .header h2 {
        border: 1px solid transparent; }
    .flowEditor .canvas .flow-step {
      background-color: #eaeaea;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      position: relative; }
      .flowEditor .canvas .flow-step .xarrow {
        z-index: 9999; }
        .flowEditor .canvas .flow-step .xarrow > path {
          pointer-events: none; }
      .flowEditor .canvas .flow-step h1 {
        color: #aaa; }
      .flowEditor .canvas .flow-step.pseudo .nodes {
        align-items: center;
        display: flex;
        justify-content: center; }
      .flowEditor .canvas .flow-step .nodes {
        padding: 0 2em 2em 2em;
        flex-grow: 1;
        display: flex; }
        .flowEditor .canvas .flow-step .nodes .new-lane {
          width: 0;
          overflow: hidden;
          transition: ease 150ms all; }
          .flowEditor .canvas .flow-step .nodes .new-lane .dropzone {
            margin-left: 1rem;
            width: 4rem;
            border: 3px dashed lightgrey;
            border-radius: .5rem;
            background: #ddd;
            box-sizing: border-box;
            -webkit-writing-mode: vertical-lr;
                -ms-writing-mode: tb-lr;
                    writing-mode: vertical-lr;
            padding-top: 30rem;
            display: flex;
            align-items: center;
            font-size: 2em;
            color: #888;
            text-overflow: nowrap; }
          .flowEditor .canvas .flow-step .nodes .new-lane .dropzone:hover {
            background: #776bc7;
            color: #fff;
            border-color: #fff;
            border-style: solid; }
        .flowEditor .canvas .flow-step .nodes .new-lane.is-dragging {
          width: 5em; }
        .flowEditor .canvas .flow-step .nodes .lane {
          display: flex;
          min-width: 180px;
          flex-direction: column; }
          .flowEditor .canvas .flow-step .nodes .lane + .lane {
            padding-left: 1em;
            margin-left: 1em;
            border-left: 5px dotted lightgray; }
        .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node-inner-wrapper {
          transition: 150ms ease all;
          border-radius: .4em; }
          .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node-inner-wrapper.is-dragging {
            box-shadow: 0 0 2px 2px #48399e; }
        .flowEditor .canvas .flow-step .nodes .flow-node-wrapper + .flow-node-wrapper {
          margin-top: 1em; }
        .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node {
          border: 1px solid #48399E;
          border-radius: .4em;
          box-shadow: 1px 1px 1px #0000004a;
          position: relative;
          background: #fff;
          transition: all 300ms ease;
          font-family: "Heebo";
          font-weight: 500;
          font-size: 12.25px;
          text-align: center;
          width: 180px; }
          .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node:hover {
            border-color: #6656c2;
            box-shadow: 2px 2px 5px #0000004a; }
            .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node:hover h3 {
              background: #6656c2; }
          .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .isId {
            background: #e3bd01; }
          .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions {
            padding: .5em 1em; }
            .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action {
              margin: 1em 0; }
              .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.question {
                border-left: 2px solid #48399E;
                border-radius: 5px;
                padding: 0 0 0 1em; }
                .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.question .quick-replies {
                  margin-top: .5em; }
                  .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.question .quick-replies > span {
                    display: inline-block;
                    border-left: 2px solid #000;
                    border-right: 2px solid #000;
                    border-radius: 5px;
                    padding: .0 .5em 0 .5em; }
                  .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.question .quick-replies .replies {
                    max-height: 0;
                    overflow: hidden;
                    transition: 500ms ease all; }
                  .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.question .quick-replies.opened .replies {
                    max-height: 200px;
                    overflow: hidden; }
                .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.question .variable {
                  background: #e3bd01;
                  margin: 0 .5em .5em .5em;
                  width: auto;
                  border-radius: 1em; }
              .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.saveApplication, .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.saveLead, .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.saveFeedback {
                border-left: 2px solid #26935c;
                border-radius: 5px;
                padding: 0 0 0 1em; }
                .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.saveApplication .message, .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.saveLead .message, .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.saveFeedback .message {
                  color: #fff;
                  padding: .5em;
                  background: #26935c; }
                .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.saveApplication .variable, .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.saveLead .variable, .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.saveFeedback .variable {
                  border: 2px solid #26935c;
                  margin: 0 .5em .5em .5em;
                  width: auto;
                  border-radius: 1em;
                  margin-top: .5em; }
              .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.question.collect {
                border-color: #e3bd01; }
              .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.question .answer {
                display: flex;
                flex-direction: row-reverse; }
              .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.question .answer-button, .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.goToNode,
              .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.personalData .answer {
                border: 1px solid #48399E;
                border-radius: .5em;
                display: block;
                min-height: 2em;
                line-height: 2em;
                margin-top: .5em;
                padding: 0 1em;
                position: relative;
                text-align: center;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); }
                .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.question .answer-button .out-anchor-wrapper, .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.goToNode .out-anchor-wrapper,
                .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.personalData .answer .out-anchor-wrapper {
                  margin-top: -.5em;
                  position: absolute;
                  height: 1em;
                  right: -1.7em;
                  top: 50%;
                  width: 1em;
                  z-index: 1; }
                  .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.question .answer-button .out-anchor-wrapper .out-anchor, .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.goToNode .out-anchor-wrapper .out-anchor,
                  .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.personalData .answer .out-anchor-wrapper .out-anchor {
                    width: 1em;
                    height: 1em;
                    background-color: #48399e;
                    border-radius: 50%; }
              .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.message,
              .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action .message {
                background: #eaeaea;
                padding: .5em 1em;
                border-radius: .5em;
                text-align: left;
                padding: .5em .75em;
                box-shadow: 0px 1px 2px 1px #0000003f; }
                .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action.message .link-cta,
                .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action .message .link-cta {
                  opacity: .5;
                  margin-top: .5em;
                  background: #fff;
                  border: none;
                  border-radius: .5rem;
                  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
                  font-weight: 500;
                  padding: .25rem 1rem;
                  text-align: center;
                  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }
              .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .actions .action .variable-highlight {
                background: #e3bd01; }
          .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node h3 {
            border-radius: .4em .4em 0 0;
            padding: .5em .5em .5em .8em;
            background: #48399E;
            color: #fff;
            transition: background 300ms ease;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-size: .8em;
            margin: 0; }
          .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .menu {
            padding: .5em 1em; }
            .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node .menu .btn {
              color: #48399E;
              width: 100%;
              text-decoration: none; }
          .flowEditor .canvas .flow-step .nodes .flow-node-wrapper .flow-node > .message {
            padding: 1.5em 0 0 0; }
        .flowEditor .canvas .flow-step .nodes * {
          cursor: pointer; }
      .flowEditor .canvas .flow-step #add-step {
        display: block;
        color: #aaa;
        font-size: 1.5em;
        transition: color ease 250ms, transform ease 50ms;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none; }
        .flowEditor .canvas .flow-step #add-step i {
          font-size: 5em;
          display: block; }
        .flowEditor .canvas .flow-step #add-step:hover {
          color: #666; }
        .flowEditor .canvas .flow-step #add-step:active {
          transform: scale(0.93); }
    .flowEditor .canvas .menu {
      display: block;
      align-self: flex-end;
      width: 100%;
      padding: 1em; }
      .flowEditor .canvas .menu .btn {
        opacity: .5; }
    .flowEditor .canvas > menu {
      position: absolute; }
    .flowEditor .canvas button,
    .flowEditor .canvas .btn {
      background: #ffffff;
      border: 2px solid #48399E;
      border-radius: 1.5em;
      cursor: pointer;
      display: block;
      line-height: 1em;
      margin: .5em auto;
      padding: .5em;
      text-align: center;
      width: 14em;
      transition: all ease 150ms;
      box-shadow: 0 0 0 0; }
      .flowEditor .canvas button:hover,
      .flowEditor .canvas .btn:hover {
        opacity: 1;
        box-shadow: 2px 2px 5px #0000004a; }
      .flowEditor .canvas button:active,
      .flowEditor .canvas .btn:active {
        transform: scale(0.95);
        color: #555;
        border-color: #776bc7; }
    .flowEditor .canvas .btn.small {
      font-size: .7em;
      padding: .5 0; }
  .flowEditor .component-editor {
    background-color: #ffffffaa;
    box-shadow: inset -0.3em 0 10px #00000087;
    height: 100%;
    left: -21em;
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: left 300ms ease;
    width: 21em;
    z-index: 99;
    padding-bottom: 4em; }
    .flowEditor .component-editor .node-editor {
      height: 100%;
      overflow-y: auto; }
      .flowEditor .component-editor .node-editor .editor-wrapper {
        display: flex;
        height: 100%;
        flex-direction: column; }
        .flowEditor .component-editor .node-editor .editor-wrapper .header {
          display: flex;
          justify-content: space-between; }
          .flowEditor .component-editor .node-editor .editor-wrapper .header h2,
          .flowEditor .component-editor .node-editor .editor-wrapper .header input {
            width: 12em;
            display: block;
            margin: .5em .5em;
            font-size: 1.25em;
            text-align: left;
            border: 1px solid #333;
            line-height: 1em;
            height: 1em;
            padding: .5em;
            font-weight: 700;
            font-family: 'Poppins', sans-serif;
            -webkit-appearance: none;
            box-sizing: content-box; }
          .flowEditor .component-editor .node-editor .editor-wrapper .header h2 {
            border: 1px solid transparent; }
          .flowEditor .component-editor .node-editor .editor-wrapper .header a.save {
            cursor: pointer;
            display: flex; }
            .flowEditor .component-editor .node-editor .editor-wrapper .header a.save > i {
              line-height: 2.5em; }
              .flowEditor .component-editor .node-editor .editor-wrapper .header a.save > i:hover {
                color: #999; }
              .flowEditor .component-editor .node-editor .editor-wrapper .header a.save > i:active {
                color: #bbb; }
        .flowEditor .component-editor .node-editor .editor-wrapper .properties {
          margin: 0 1em; }
          .flowEditor .component-editor .node-editor .editor-wrapper .properties > div {
            border-left: 2px solid #aaa;
            padding: 0 1em; }
        .flowEditor .component-editor .node-editor .editor-wrapper h3 {
          margin: 0 0;
          text-align: center; }
        .flowEditor .component-editor .node-editor .editor-wrapper .actions {
          border: 4px dashed #ccc;
          border-radius: 10px;
          flex-grow: 1;
          font-size: .8em;
          margin: 1em; }
          .flowEditor .component-editor .node-editor .editor-wrapper .actions .no-actions {
            padding: 2em;
            text-align: center; }
          .flowEditor .component-editor .node-editor .editor-wrapper .actions .action {
            display: flex;
            justify-content: space-between;
            -webkit-animation: slide-up 0.4s ease;
                    animation: slide-up 0.4s ease; }
            .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.goToNode {
              margin-top: 1em; }
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.goToNode > div {
                border: 2px solid #48399E;
                border-radius: 1.5em;
                line-height: 2em;
                margin: .5em 1em;
                padding: 0 5em;
                text-align: center; }
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.goToNode > i {
                margin: .3em .7em; }
            .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree {
              border-color: #e3bd01 !important;
              padding-top: 1em; }
            .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead {
              border-color: #26935c !important;
              padding-top: 1em; }
            .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink {
              border-color: #ddd !important;
              display: block; }
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .message-extra {
                text-align: center;
                margin-top: 1em; }
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .message-extra .button-cta {
                  border: none;
                  border-radius: .5rem;
                  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
                  font-weight: 500;
                  padding: .5rem 1rem;
                  text-align: center;
                  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }
            .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink {
              margin: .5em;
              border-left: 2px solid #48399E;
              border-radius: 10px;
              position: relative; }
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question select,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .label, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData select,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .label, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed select,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .label, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree select,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .label, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication select,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .label, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback select,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .label, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead select,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .label, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink select,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .label {
                padding: .5em 1em;
                border: 2px solid #e3bd01;
                border-radius: 2em;
                width: 15em; }
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question select:focus, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData select:focus, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed select:focus, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree select:focus, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication select:focus, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback select:focus, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead select:focus, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink select:focus {
                outline: none; }
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .label, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .label, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .label, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .label, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .label, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .label, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .label, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .label {
                margin-bottom: 1em;
                margin-left: auto;
                margin-right: auto;
                border-color: #26935c; }
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question > i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData > i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed > i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree > i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication > i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback > i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead > i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink > i {
                position: absolute;
                top: 0;
                right: 0;
                margin-right: .42em; }
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents {
                display: flex;
                flex-direction: column;
                text-align: center;
                width: 100%; }
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .message-content,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .message-content, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .message-content,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .message-content, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .message-content,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .message-content, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .message-content,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .message-content, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .message-content,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .message-content, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .message-content,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .message-content, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .message-content,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .message-content, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .message-content,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .message-content {
                  max-width: 15em;
                  flex-shrink: 1; }
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .quick-replies > span,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .quick-replies > span, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .quick-replies > span,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .quick-replies > span, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .quick-replies > span,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .quick-replies > span, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .quick-replies > span,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .quick-replies > span, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .quick-replies > span,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .quick-replies > span, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .quick-replies > span,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .quick-replies > span, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .quick-replies > span,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .quick-replies > span, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .quick-replies > span,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .quick-replies > span {
                  margin: .5em auto;
                  padding: 0 .5em;
                  border-left: 2px #000 solid;
                  border-right: 2px #000 solid;
                  border-radius: .5em;
                  display: inline-block;
                  text-align: center; }
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .answers,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .variables,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .answers,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .variables, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .answers,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .variables,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .answers,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .variables, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .answers,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .variables,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .answers,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .variables, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .answers,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .variables,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .answers,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .variables, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .answers,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .variables,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .answers,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .variables, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .answers,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .variables,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .answers,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .variables, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .answers,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .variables,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .answers,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .variables, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .answers,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .variables,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .answers,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .variables {
                  display: flex;
                  flex-flow: row-reverse wrap;
                  margin-bottom: 1em;
                  width: 100%; }
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .answers .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .answers .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .variables .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .variables .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .answers .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .answers .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .variables .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .variables .variable-row, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .answers .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .answers .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .variables .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .variables .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .answers .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .answers .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .variables .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .variables .variable-row, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .answers .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .answers .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .variables .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .variables .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .answers .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .answers .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .variables .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .variables .variable-row, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .answers .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .answers .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .variables .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .variables .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .answers .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .answers .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .variables .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .variables .variable-row, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .answers .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .answers .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .variables .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .variables .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .answers .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .answers .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .variables .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .variables .variable-row, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .answers .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .answers .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .variables .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .variables .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .answers .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .answers .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .variables .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .variables .variable-row, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .answers .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .answers .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .variables .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .variables .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .answers .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .answers .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .variables .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .variables .variable-row, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .answers .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .answers .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .variables .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .variables .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .answers .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .answers .variable-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .variables .answer-row,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .variables .variable-row {
                    display: flex;
                    align-items: center; }
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .answers .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .answers .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .variables .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .variables .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .answers .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .answers .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .variables .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .variables .variable-row i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .answers .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .answers .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .variables .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .variables .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .answers .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .answers .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .variables .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .variables .variable-row i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .answers .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .answers .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .variables .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .variables .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .answers .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .answers .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .variables .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .variables .variable-row i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .answers .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .answers .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .variables .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .variables .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .answers .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .answers .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .variables .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .variables .variable-row i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .answers .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .answers .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .variables .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .variables .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .answers .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .answers .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .variables .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .variables .variable-row i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .answers .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .answers .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .variables .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .variables .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .answers .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .answers .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .variables .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .variables .variable-row i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .answers .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .answers .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .variables .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .variables .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .answers .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .answers .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .variables .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .variables .variable-row i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .answers .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .answers .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .variables .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .variables .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .answers .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .answers .variable-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .variables .answer-row i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .variables .variable-row i {
                      font-size: 1rem;
                      padding-right: .5em;
                      opacity: 0; }
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .answers .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .answers .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .variables .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .variables .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .answers .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .answers .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .variables .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .variables .variable-row:hover i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .answers .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .answers .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .variables .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .variables .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .answers .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .answers .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .variables .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .variables .variable-row:hover i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .answers .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .answers .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .variables .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .variables .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .answers .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .answers .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .variables .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .variables .variable-row:hover i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .answers .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .answers .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .variables .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .variables .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .answers .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .answers .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .variables .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .variables .variable-row:hover i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .answers .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .answers .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .variables .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .variables .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .answers .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .answers .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .variables .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .variables .variable-row:hover i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .answers .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .answers .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .variables .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .variables .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .answers .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .answers .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .variables .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .variables .variable-row:hover i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .answers .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .answers .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .variables .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .variables .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .answers .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .answers .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .variables .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .variables .variable-row:hover i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .answers .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .answers .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .variables .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .variables .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .answers .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .answers .variable-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .variables .answer-row:hover i,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .variables .variable-row:hover i {
                      opacity: 1; }
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .answers .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .answers .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .answers .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .answers .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .variables .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .variables .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .variables .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents .variables .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .answers .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .answers .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .answers .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .answers .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .variables .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .variables .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .variables .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents .variables .variable-row + .variable-row, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .answers .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .answers .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .answers .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .answers .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .variables .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .variables .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .variables .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents .variables .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .answers .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .answers .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .answers .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .answers .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .variables .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .variables .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .variables .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents .variables .variable-row + .variable-row, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .answers .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .answers .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .answers .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .answers .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .variables .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .variables .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .variables .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents .variables .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .answers .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .answers .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .answers .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .answers .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .variables .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .variables .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .variables .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents .variables .variable-row + .variable-row, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .answers .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .answers .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .answers .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .answers .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .variables .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .variables .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .variables .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents .variables .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .answers .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .answers .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .answers .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .answers .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .variables .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .variables .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .variables .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents .variables .variable-row + .variable-row, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .answers .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .answers .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .answers .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .answers .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .variables .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .variables .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .variables .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents .variables .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .answers .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .answers .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .answers .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .answers .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .variables .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .variables .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .variables .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents .variables .variable-row + .variable-row, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .answers .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .answers .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .answers .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .answers .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .variables .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .variables .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .variables .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents .variables .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .answers .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .answers .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .answers .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .answers .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .variables .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .variables .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .variables .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents .variables .variable-row + .variable-row, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .answers .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .answers .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .answers .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .answers .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .variables .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .variables .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .variables .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents .variables .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .answers .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .answers .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .answers .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .answers .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .variables .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .variables .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .variables .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents .variables .variable-row + .variable-row, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .answers .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .answers .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .answers .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .answers .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .variables .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .variables .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .variables .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents .variables .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .answers .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .answers .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .answers .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .answers .variable-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .variables .answer-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .variables .answer-row + .variable-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .variables .variable-row + .answer-row,
                    .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents .variables .variable-row + .variable-row {
                      margin-top: .5em; }
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents i,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents i,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents i,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents i,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents i,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents i,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents i,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents i, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents i,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents i {
                  cursor: pointer;
                  color: #666; }
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents i:hover,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents i:hover, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents i:hover,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents i:hover, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents i:hover,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents i:hover, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents i:hover,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents i:hover, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents i:hover,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents i:hover, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents i:hover,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents i:hover, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents i:hover,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents i:hover, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents i:hover,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents i:hover {
                    color: #ccc; }
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .question-contents i:active,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .save-application-contents i:active, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .question-contents i:active,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .save-application-contents i:active, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .question-contents i:active,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .save-application-contents i:active, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .question-contents i:active,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .save-application-contents i:active, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .question-contents i:active,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .save-application-contents i:active, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .question-contents i:active,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .save-application-contents i:active, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .question-contents i:active,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .save-application-contents i:active, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .question-contents i:active,
                  .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .save-application-contents i:active {
                    color: #aaa; }
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .answer,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .variable,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .link-href, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .answer,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .variable,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .link-href, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .answer,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .variable,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .link-href, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .answer,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .variable,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .link-href, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .answer,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .variable,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .link-href, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .answer,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .variable,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .link-href, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .answer,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .variable,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .link-href, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .answer,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .variable,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .link-href {
                border: 2px solid #48399E;
                border-radius: 1.5em;
                min-height: 2em;
                line-height: 2em;
                padding: 0 1em;
                text-align: center; }
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .answer:focus,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .variable:focus,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .link-href:focus, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .answer:focus,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .variable:focus,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .link-href:focus, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .answer:focus,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .variable:focus,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .link-href:focus, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .answer:focus,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .variable:focus,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .link-href:focus, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .answer:focus,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .variable:focus,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .link-href:focus, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .answer:focus,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .variable:focus,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .link-href:focus, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .answer:focus,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .variable:focus,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .link-href:focus, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .answer:focus,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .variable:focus,
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .link-href:focus {
                  outline: none;
                  box-shadow: 0 0 0.3em #6656c2; }
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.question .linkhref, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .linkhref, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFixed .linkhref, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.collectQuestionFree .linkhref, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveApplication .linkhref, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveFeedback .linkhref, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.saveLead .linkhref, .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.messageWithLink .linkhref {
                align-self: center; }
            .flowEditor .component-editor .node-editor .editor-wrapper .actions .action.personalData .message-content {
              font-family: 'Poppins', sans-serif;
              font-size: 1em;
              display: block;
              overflow: hidden;
              white-space: normal;
              overflow-wrap: normal;
              text-align: left;
              resize: none;
              min-height: 25px;
              padding: 10px;
              width: 100%;
              line-height: 1.2em;
              background: #ddd;
              border: none;
              border-radius: .5em; }
            .flowEditor .component-editor .node-editor .editor-wrapper .actions .action .message-content {
              position: relative;
              margin: .5em 1em;
              max-width: 15em; }
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action .message-content textarea {
                height: 100%;
                position: absolute; }
                .flowEditor .component-editor .node-editor .editor-wrapper .actions .action .message-content textarea:focus {
                  outline: none;
                  box-shadow: 0 0 0.3em #6656c2; }
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action .message-content .hidden-textarea {
                white-space: pre-wrap;
                visibility: hidden; }
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action .message-content .hidden-textarea,
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action .message-content textarea {
                font-family: 'Poppins', sans-serif;
                font-size: 1em;
                display: block;
                overflow: hidden;
                white-space: normal;
                overflow-wrap: normal;
                text-align: left;
                margin: 0 auto;
                resize: none;
                min-height: 25px;
                padding: 10px;
                width: 100%;
                line-height: 1.2em;
                background: #ddd;
                border: none;
                border-radius: .5em; }
            .flowEditor .component-editor .node-editor .editor-wrapper .actions .action > i {
              cursor: pointer;
              align-self: flex-end;
              margin: .5em .7em;
              color: #ddd; }
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action > i:hover {
                color: #ccc; }
              .flowEditor .component-editor .node-editor .editor-wrapper .actions .action > i:active {
                color: #aaa; }
      .flowEditor .component-editor .node-editor .menu {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0; }
        .flowEditor .component-editor .node-editor .menu nav ul {
          margin: 0;
          list-style: none;
          padding: .5em; }
        .flowEditor .component-editor .node-editor .menu nav.action-menu ul {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          padding: .5em;
          border: 2px solid #48399E;
          border-radius: .5em .5em 0 0;
          background: #fff;
          box-shadow: 10px 0 25px; }
          .flowEditor .component-editor .node-editor .menu nav.action-menu ul li {
            display: block;
            margin: .3em; }
            .flowEditor .component-editor .node-editor .menu nav.action-menu ul li a {
              border-radius: .3em;
              background: #48399E;
              color: #fff;
              cursor: pointer;
              display: block;
              font-size: .7em;
              opacity: 1;
              padding: .8em .5em;
              transition: all ease 100ms;
              text-align: center; }
              .flowEditor .component-editor .node-editor .menu nav.action-menu ul li a i {
                display: block; }
              .flowEditor .component-editor .node-editor .menu nav.action-menu ul li a:hover {
                opacity: .9;
                box-shadow: 2px 2px 5px #0000004a; }
              .flowEditor .component-editor .node-editor .menu nav.action-menu ul li a:active {
                transform: scale(0.98);
                background-color: #776bc7; }
        .flowEditor .component-editor .node-editor .menu nav.main-menu {
          display: flex;
          background: #ddd; }
          .flowEditor .component-editor .node-editor .menu nav.main-menu ul {
            display: flex;
            padding: 0;
            flex-grow: 1; }
            .flowEditor .component-editor .node-editor .menu nav.main-menu ul li {
              color: #48399E;
              cursor: pointer;
              height: 4em;
              flex-grow: 1;
              text-align: center;
              transition: opacity ease 100ms;
              width: 50%; }
              .flowEditor .component-editor .node-editor .menu nav.main-menu ul li a {
                display: block;
                height: 100%;
                width: 100%;
                padding: .5em; }
                .flowEditor .component-editor .node-editor .menu nav.main-menu ul li a span {
                  display: block;
                  font-size: .8em; }
                .flowEditor .component-editor .node-editor .menu nav.main-menu ul li a i {
                  display: block;
                  font-size: 2em; }
              .flowEditor .component-editor .node-editor .menu nav.main-menu ul li:hover {
                opacity: .9;
                background: #eee; }
              .flowEditor .component-editor .node-editor .menu nav.main-menu ul li:active {
                opacity: .85; }
              .flowEditor .component-editor .node-editor .menu nav.main-menu ul li.red {
                background: #b02020; }
                .flowEditor .component-editor .node-editor .menu nav.main-menu ul li.red i {
                  color: #fff; }
              .flowEditor .component-editor .node-editor .menu nav.main-menu ul li.save {
                color: #fff;
                background: #48399E; }
                .flowEditor .component-editor .node-editor .menu nav.main-menu ul li.save i {
                  color: #fff; }
            .flowEditor .component-editor .node-editor .menu nav.main-menu ul.square {
              flex-grow: 0; }
              .flowEditor .component-editor .node-editor .menu nav.main-menu ul.square li {
                width: 4em; }
                .flowEditor .component-editor .node-editor .menu nav.main-menu ul.square li a {
                  padding: 0;
                  width: 100%;
                  overflow: hidden;
                  color: #aaa; }
                  .flowEditor .component-editor .node-editor .menu nav.main-menu ul.square li a i {
                    line-height: 2em; }
    .flowEditor .component-editor .subtle {
      color: #999; }
  .flowEditor .component-editor.show {
    left: 0; }
  .flowEditor .floating-menu {
    position: fixed;
    bottom: 0;
    right: 0; }
    .flowEditor .floating-menu #client-flows {
      width: 15em; }
      .flowEditor .floating-menu #client-flows li {
        display: flex; }
        .flowEditor .floating-menu #client-flows li span {
          flex-grow: 1; }
        .flowEditor .floating-menu #client-flows li button {
          background: none;
          border: none; }
    .flowEditor .floating-menu #settings,
    .flowEditor .floating-menu #client-flows {
      background: #fff;
      border: 2px solid #48399E;
      border-radius: 1em;
      padding: .5em;
      margin: 1em;
      -webkit-animation: slide-up 0.4s ease;
              animation: slide-up 0.4s ease;
      justify-content: center; }
      .flowEditor .floating-menu #settings h2,
      .flowEditor .floating-menu #settings h3,
      .flowEditor .floating-menu #client-flows h2,
      .flowEditor .floating-menu #client-flows h3 {
        text-align: center;
        margin: .2em 0; }
      .flowEditor .floating-menu #settings ul,
      .flowEditor .floating-menu #client-flows ul {
        list-style: none;
        margin: 0;
        padding: 0; }
        .flowEditor .floating-menu #settings ul li,
        .flowEditor .floating-menu #client-flows ul li {
          border: 2px solid #e3bd01;
          border-radius: 2em;
          display: flex;
          font-size: .75em;
          line-height: 2em;
          padding: .2em .5em;
          text-align: center; }
          .flowEditor .floating-menu #settings ul li input,
          .flowEditor .floating-menu #client-flows ul li input {
            border: none; }
          .flowEditor .floating-menu #settings ul li input:disabled,
          .flowEditor .floating-menu #client-flows ul li input:disabled {
            background: #fff; }
          .flowEditor .floating-menu #settings ul li div,
          .flowEditor .floating-menu #client-flows ul li div {
            flex-grow: 1; }
          .flowEditor .floating-menu #settings ul li + li,
          .flowEditor .floating-menu #client-flows ul li + li {
            margin-top: .5em; }
          .flowEditor .floating-menu #settings ul li i,
          .flowEditor .floating-menu #client-flows ul li i {
            cursor: pointer; }
    .flowEditor .floating-menu #floating-btns {
      position: relative; }
    .flowEditor .floating-menu .floating-btn {
      width: 5em;
      height: 5em;
      border-radius: 50%;
      color: #fff;
      margin: 1em;
      margin-left: auto;
      box-shadow: 0 0 0 #00000000;
      transition: ease all 200ms;
      cursor: pointer;
      opacity: 1;
      background: #ccc;
      position: relative; }
      .flowEditor .floating-menu .floating-btn i {
        font-size: 3em;
        text-align: center;
        display: block;
        line-height: 5rem; }
      .flowEditor .floating-menu .floating-btn span {
        opacity: 0; }
      .flowEditor .floating-menu .floating-btn.save-btn {
        background: green; }
        .flowEditor .floating-menu .floating-btn.save-btn i {
          line-height: 4.5rem; }
      .flowEditor .floating-menu .floating-btn:hover {
        box-shadow: 0.1em 0.1em 0.4em #00000082; }
        .flowEditor .floating-menu .floating-btn:hover span {
          opacity: 1;
          font-size: .8em;
          display: block;
          position: absolute;
          top: -2em;
          background: #777;
          padding: .5em;
          width: 6em;
          border-radius: 1em;
          box-shadow: 0.1em 0.1em 0.4em #00000082;
          -webkit-animation: slide-up 0.4s ease;
                  animation: slide-up 0.4s ease;
          text-align: center; }
      .flowEditor .floating-menu .floating-btn:active {
        opacity: .9; }

.flow-step + .flow-step {
  border-left: 3px dashed lightgray; }

@-webkit-keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

